import {Deserializable} from "./deserializable";
export class Settoken implements Deserializable{
    _id:any;
    index:any;
    name:any;
    tokenAddress:any;

    deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }
}
