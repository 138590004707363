<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card">
              <div class="card-body p-4">
                <form [formGroup]="login" (ngSubmit)="onSubmit(login)">
                  <h1 translate>Login</h1>
                  <p class="text-muted" translate>Sign In to your account</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Enter Username" formControlName="username"
                      autocomplete="username" required>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Enter Password" formControlName="password"
                      autocomplete="current-password" required>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-main btn-fullwidth color-2">{{button_title |
                        translate}}</button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" class="btn px-0 link-cs" routerLink="/forgot" translate>Forgot
                        password?</button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="card-footer p-4" *ngIf="allowSignUp">
                <div class="row">
                  <div class="col-6">
                    <button class="btn btn-block btn-facebook" type="button" (click)="signInWithFacebook()"><i
                        class="fa fa-facebook"></i><span>Facebook</span></button>
                  </div>
                  <div class="col-6">
                    <button class="btn btn-block btn-twitter" type="button" (click)="signInWithGoogle()"><i
                        class="fa fa-google"></i><span>Google</span></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card text-white py-5 d-md-down-none btn-cs" style="width:44%">
              <div class="card-body text-center">
                <div *ngIf="allowSignUp">
                  <h2 translate>Sign up</h2>
                  <p translate>Click below button to signup with MiT NFT Marketplace. You can signup with facebook and
                    google as well</p>
                  <button type="button" class="btn btn-primary mt-3" routerLink="/register" translate>Register
                    Now!</button>
                </div>
                <div *ngIf="!allowSignUp">
                  <h2 translate>Sign up</h2>
                  <p translate>Modo Demo activado, por ahora no es posible registrarse.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>