import {Component, Input, OnInit} from '@angular/core';
import {config} from '../../../constants/config';

@Component({
  selector: 'app-items-carousel',
  templateUrl: './items-carousel.component.html',
  styleUrls: ['./items-carousel.component.css']
})
export class ItemsCarouselComponent implements OnInit {
@Input('itemsActivatedForSale') itemsActivatedForSale: any;

  mediaBase: string = config.media_path;
  constructor() { }

  ngOnInit(): void {
  }

}
