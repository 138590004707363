<div class="blog">

   <div>
    <div>
       <h4 style="color: #04D8D7; padding: 2rem;"><span>{{title}}</span></h4> 
    </div>
    <div>
        <div class="image"  [ngStyle]="{'background-image': 'url(' + img + ')'}"></div>
    </div>

  </div>
    <div style="position: absolute;    bottom: 12px;
    margin: 32px;">
        <div class="left">
            <span class="cd-text1">{{name}}</span>
        </div>
        <div class="left">
            <span class="cd-text2">{{description}}</span>
        </div>
        <div class="left">
            <span class="cd-text3" style="cursor: pointer;"><i class="fa fa-arrow-right" style="font-size: 20px; color:#04D8D7"></i></span>
        </div>
    </div>

</div>


