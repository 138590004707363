import {Deserializable} from "./deserializable";
export class Setacceptedtoken implements Deserializable{
    collection_id:any;
    acceptedTokenIndex:any;
    acceptedToken:any;

    deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }
}