<div class="profileContainer" 
    #profileContainer style="background-color: rgb(19, 23, 48);">
    <div class="topContainer" #topContainer>
        <div class="profileHeader">
            <div class="profileCover">
                <img [src]="mediaBase+'/images/cover/'+profile_cover">
                <div class="editAction" *ngIf="ownProfile">
                    <button class="btn btn-secondary"><i class="icon-note"></i></button>
                    <input type="file" ng2FileSelect [uploader]="cover_uploader" (change)="uploadCover()" multiple />
                </div>
            </div>
            <div class="settingsAction" *ngIf="ownProfile">
                <button class="btn btn-secondary" routerLink="/settings"><i class="icon-settings"></i>
                    {{'Settings'|translate}}</button>
            </div>
            <div class="profileAvatar">
                <div class="profileAvatarInner">
                    <img [src]="mediaBase+'/images/user/'+profile_image">
                    <div class="avatarAction" *ngIf="ownProfile">
                        <i class="icon-pencil"></i>
                        <input type="file" ng2FileSelect [uploader]="uploader" (change)="uploadAvatar()" multiple />
                    </div>
                </div>


            </div>
        </div>
        <div class="profileSubHeader" *ngIf="userInfo!=null">
            <h2>{{profile_name}}</h2>
            <p (click)="copyAddress()">
                {{userInfo.public_key.substring(0,4)}}...{{userInfo.public_key.substring(userInfo.public_key.length-4)}}<i
                    class="fa fa-copy"></i></p>
        </div>
        <div>
  
            <div class="tabmenu-1 container"> 
                <ul>
                    <li (click)="profileLink('collected')" class="tab-item active"  [ngClass]="{'active': type=='collected'}"><a href="javascript:void(0);"
                            ><i class="cil-basket"></i><span 
                                translate>Collected</span></a></li>
                    <li (click)="profileLink('created')" class="tab-item" [ngClass]="{'active': type=='created'}"><a href="javascript:void(0);"
                            ><i class="cil-note-add"></i><span 
                                translate>Created</span></a></li>
                    <li  (click)="profileLink('favorited')" class="tab-item"  [ngClass]="{'active': type=='favorited'}"><a href="javascript:void(0);"
                           ><i class="cil-heart"></i><span 
                                translate>Favorites</span></a></li>
                    <li (click)="profileLink('activity')"  class="tab-item" [ngClass]="{'active': type=='activity'}"><a href="javascript:void(0);"
                            ><i class="cil-bar-chart"></i><span
                                 translate>Activity</span></a></li>
                </ul>
            
           </div>

                <div class="container-fluid">
                    <router-outlet></router-outlet>
                </div>
        </div>
    </div>


</div>
<app-loader *ngIf="spinner"></app-loader>
