import { AfterViewChecked, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CollapseDirective } from 'ngx-bootstrap/collapse';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { config } from './constants/config';
import { ItemService } from 'src/app/services/item.service';
import { CommonService } from 'src/app/services/common.service';
import { OnboardService } from 'src/app/services/onboard.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApService } from './services/ap.service';
// import Onboard from 'bnc-onboard';
import Web3 from 'web3';
import { Observable } from 'rxjs';
// import { init as cookiesInit, configure as cookiesConfigure } from 'cookie-though';
// import Config from 'cookie-though/types';

declare var $: any;
declare var window: any;
declare var loadJs: Function;
let web3;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {
  @ViewChild('btnLogout', { static: false }) btnLogout: ElementRef | undefined;
  @ViewChild('btnConnecWallet', { static: false }) btnConnecWallet: ElementRef | undefined;

  mediaBase: string = config.media_path;
  title = 'nftfrontend';
  user: any = null;
  today: number = Date.now();
  profile_image = '';
  current_page = '';
  apply_dark_theme = false;
  categories: any = [];
  categoriesLimited: any = [];
  searchInput = '';
  current_lang = 'es';
  available_langs = ['es', 'gb'];
  // private window: any;
  private _isCollapsed = true;
  set isCollapsed(value) {
    this._isCollapsed = value;
  }
  get isCollapsed() {
    if (this.collapseRef) {
      // temp fix for "overflow: hidden"
      if (getComputedStyle(this.collapseRef.nativeElement).getPropertyValue('display') === 'flex') {
        this.renderer.removeStyle(this.collapseRef.nativeElement, 'overflow');
      }
    }
    return this._isCollapsed;
  }
  @ViewChild(CollapseDirective, { read: ElementRef, static: false }) collapse !: CollapseDirective;
  collapseRef: any;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private itemService: ItemService,
    private commonService: CommonService,
    private onboardService: OnboardService,
    private snackBar: MatSnackBar,
    private eleRef: ElementRef,
    private route: ActivatedRoute,
    private apService: ApService
    // private location:Location
  ) {
    // this.user = this.userService.getUser();
    this.userService.user.subscribe((x) => {
      this.user = x;
      if (x == null) { return; }
      this.profile_image = this.user.profile_image !== '' ? this.user.profile_image : 'nouser.jpg';
    });

    const getAccount = this.getAccount;
    const connectWallet = this.connectWallet;
    const checkWallet = () => {
      if (getAccount()) {
        this.btnLogout?.nativeElement.click();
        this.btnConnecWallet?.nativeElement.click();
      } else {
        if (this.user) {
          this.btnLogout?.nativeElement.click();
        }
      }
    };
    checkWallet();
    // const that = this;
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', checkWallet);
    }

    this.current_lang = localStorage.getItem('lang') || 'es';
    this.translate.addLangs(this.available_langs);
    this.translate.setDefaultLang('es');
    this.translate.use(this.current_lang);

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      $('#back-to-top').click();
      if ($('header').height() > 100) {
        $('#menu-btn').click();
      }
      if (this.user != null) {
        if (this.user.status === 'reset') {
          this.router.navigate(['/reset']);
        }
      }

      this.apply_dark_theme = false;
      if(event.url == "/"){
        this.apply_dark_theme = true;
      }
      let url = event.url.split('/');
      // console.log(url);
      if (url[1].includes('?')) {
        let suburl = url[1].split('?');
        this.current_page = suburl[0];
      } else {
        this.current_page = url[1];
      }
      // console.log('current_page:', this.current_page);
      // console.log('url:', event);

      // console.log("data:", this.router.getCurrentNavigation()?.extras.state);
      console.log(this.current_page);
      if (['marketplace', 'stats'].filter((item) => this.current_page.includes(item)).length != 0) {
        this.apply_dark_theme = true;
      }
    });

    this.getCategories();
    this.getSettings();
    // this.initCookiesConcent();
  }

  async getAccount() {
    if (!window.ethereum) { return false; }
    const accounts = await window.ethereum?.enable();
    if (accounts?.length === 0) { return false; }
    return accounts[0];
    // do something with new account here
    // console.log('account:', account);
    // this.connectWallet();
  }

  async connectWallet() {
    if (window.ethereum?.selectedAddress == null || +window.ethereum?.chainId !== this.onboardService.networkId) {
      const walletSelected = await this.onboardService.walletSelect();
      // console.log('walletSelected:', walletSelected);
      const readyToTransact = await this.onboardService.walletCheck();
      // console.log('readyToTransact:', readyToTransact);
    }

    if (window.ethereum?.selectedAddress && +window.ethereum?.chainId === this.onboardService.networkId) {
      // do Login
      const wallet = window.ethereum?.selectedAddress;
      // console.log('do Login with wallet: ', window.ethereum?.selectedAddress);
      const params = { username: wallet, public_key: wallet };
      this.loginApi(params);
    } else {
      // console.log('you need to select the correct chain');
    }
  }

  /**
 * This is the function which used to send login information with params to api
 */
  loginApi = async (params: any) => {
    this.userService.getChallenge(String(params.public_key).toLowerCase()).subscribe(async resultChallenge => {
      // console.log('resultChallenge: ', resultChallenge);
      if (!resultChallenge) {
        this.snackBar.open(this.translate.instant('Error getting Challenge'), '');
        return;
      }
      let resultChallengeSigned;
      try {
        resultChallengeSigned = await this.onboardService.signChallenge(resultChallenge);
        console.log('resultChallengeSigned:', resultChallengeSigned);
      }
      catch (err) {
        console.error(err);
        return;
      }

      if (!resultChallengeSigned) {
        this.snackBar.open(this.translate.instant('Error getting Challenge'), '', { duration: 2000 });
        return;
      }

      console.log('resultChallenge:', resultChallenge);

      const message: { value: string } = Array.isArray(resultChallenge) && resultChallenge.length > 0 ? resultChallenge[1] : Object.create({ "value": '1' });

      this.userService.loginWallet(message.value, String(resultChallengeSigned)).subscribe(result => {
        console.log('result', result);
        if (result.status === true) {
          localStorage.setItem('token', result.return_id);
          this.userService.refreshUser();
          this.snackBar.open(this.translate.instant('Login Successful'), "", { duration: 2000 });
          
          // Aquí es donde se hace el registro del usuario en AP (YGM)
          this.apService.getApAdminToken(this.userService.getWallet()).subscribe((response: string) => {
              this.apService.setApToken(response);

              var user = this.userService.getUser();
              var refer_id = this.userService.getReferId(); 

              this.apService.registerUser(this.userService.getWallet(),refer_id,user).subscribe(response => {
                console.log('Register user: ', response);
                this.userService.deleteReferId();
                this.userService.setMyReferId(response.refer_code);
              });
            });
        } else {
          this.snackBar.open(result.message, '', { duration: 2000 });
        }
      });
    });
  };

  search() {
    if (this.searchInput.length > 0) {
      this.router.navigate([`/marketplace`], { queryParams: { q: this.searchInput } });
      this.apply_dark_theme = true;
      this.searchInput = "";
    }
  }

  ngOnInit() {
    console.log('OnInit');
    this.onboardService.walletAccount.subscribe((response: string) => {
      console.log('Wallet address ', response);
      this.userService.setWallet(response);
    })
    if (this.user != null) {
      this.profile_image = this.user.profile_image != '' ? this.user.profile_image : 'nouser.jpg';
      if (this.user.status == 'reset') {
        this.router.navigate(['/reset']);
      }
    }
    
  }

  availableLangs() {
    return this.available_langs.filter(l => l != this.current_lang);
  }

  changeLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.current_lang = lang;
    this.translate.use(lang);
    this.collapseMenu();
  }

  ngAfterViewChecked(): void {
    this.collapseRef = this.collapse;
  }

  /**
   * This is the function which used to logout user from the application on menu click
   */
  logoutAction = () => {
    localStorage.removeItem('token');
    this.userService.refreshUser();
    // location.href = config.base_url;
    this.router.navigate(['/']);
  };

  collapseMenu = () => {
    this._isCollapsed = true;
  };

  /**
   * This is the function which used to retreive category list from api
   */
  getCategories = () => {
    this.itemService.categoryList().subscribe(result => {
      if (result.status == true) {
        // console.log('categories:', result);
        this.categoriesLimited = [...result.tempArray];
        this.categories = [...result.tempArray].filter((_, i) => i <= 5);
      }
    });
  }

  getSettings = () => {
    this.commonService.loadSettings().subscribe(result => {
      if (result.status === true) {
        // console.log('settings:', result);
        this.commonService.updateSetting(result.data);
        // this.commonService.updateEthNetwork(result.data.eth_network);
        // this.commonService.updateCommission(parseFloat(result.data.admin_commission));
      }
    });
  }
}
