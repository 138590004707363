import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-refer-register',
  templateUrl: './refer-register.component.html',
  styleUrls: ['./refer-register.component.css']
})
export class ReferRegisterComponent implements OnInit {

  constructor(private _router: Router, private _route: ActivatedRoute, private userService: UserService){
    
    var refer_id = _route.snapshot.params['refer_id']; 
    
    this.userService.setReferId(refer_id);

    _router.navigate(['/']);
 } 

  ngOnInit(): void {
  }

}
