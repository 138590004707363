import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-section-two-items',
  templateUrl: './section-two-items.component.html',
  styleUrls: ['./section-two-items.component.css']
})
export class SectionTwoItemsComponent implements OnInit {
  @Input('img') img = '';
  @Input('title') title = '';
  @Input('name') name = '';
  @Input('description') description = '';

  constructor() { }

  ngOnInit(): void {
  }

}
